<template>
  <div class="card mb-2">
    <div class="card-body p-3" style="background-color: #F8F8F9">
      <div class="row">
        <div class="col-md-12">
          <span style="font-weight: 700; font-size: 12px; color: black">Ad <span class="me-2 ms-2">· </span></span>
          <span>amazon.com</span>
        </div>
      </div>

      <div class="row my-2">
        <div class="col-md-12">
          <p style="color: #1558d6; font-size: 18px; line-height: 24px;">
            {{ adPreview.header }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <p style="font-size: 14px; line-height: 20px;">
            {{ adPreview.description }}
          </p>
        </div>
      </div>
    </div><!--end card-body-->
  </div>
</template>

<script>
export default {
  props: ['formData'],
  data() {
    return {
      form: {}
    }
  },
  computed: {
    adPreview() {
      return {
        header: (this.form.headingInput[0].value ? this.form.headingInput[0].value : "<Product Name>") + " | " + (this.form.headingInput[1].value ? this.form.headingInput[1].value : "<Feature or Benefit 1>") + " | " + (this.form.headingInput[2].value ? this.form.headingInput[2].value : "<Feature or Benefit 2>"),
        description: (this.form.descriptionInput[0].value ? this.form.descriptionInput[0].value : '<Description 1 will be shown here and should include additional details and benefits>') + ' ' + (this.form.descriptionInput[0].value ? this.form.descriptionInput[1].value : '<Additional Descriptions may be shown here (subject to Google optimization>')
      }
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
}
</script>