<template>
  <div class="row">
    <div class="col-md-12">
      <page-title-component title="Send Email"></page-title-component>

      <div  class="card">
        <div class="card-body py-5">
          <div class="row">
            <div class="col-md-12">
              <editor :api-key="tinyMCEApiKey"
                      v-model="form.body_html"
                      output-format="html"/>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-8">
              <select class="select2 select-lists"
                      v-show="showLists"
                      style="width: 100%"
                      multiple="multiple"
                      v-model="form.list_ids"
                      data-placeholder="Select List">
                <option :value="list.id"
                        v-for="list in lists"
                        :key="'list_'+list.id">{{ list.name }}</option>
              </select>
            </div>
            <div class="col-4 text-right">
              <router-link :to="{ name: 'influencer_details', params: { id: this.$route.params.id } }"
                           class="btn btn-dark mr-3">Skip</router-link>
              <button class="btn btn-primary"
                      @click="sendEmail"
                      :disabled="!form.list_ids.length || !form.body_html.length || loading">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitleComponent from "@/components/PageTitleComponent.vue";
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {PageTitleComponent, Editor},
  data() {
    return {
      showLists: false,
      form: {
        body_html: '',
        list_ids: [],
				id: this.$route.params.id
      },
      lists: []
    }
  },
  mounted() {
    this.loadLists();
  },
  methods: {
    sendEmail() {
      this.loading = true;

      this.axios.post('/email/send', this.form)
        .then(() => {
          this.$router.push({ name: 'influencer_details', params: { id: this.$route.params.id } })
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadLists() {
      this.lists = [];

      this.axios.get('/email/lists')
        .then(res => {
          this.lists = res.data.data;
          this.initListsSelect2()
        })
    },
    initListsSelect2() {
      let self = this;

      window.$('.select-lists').select2();

      window.$('.select-lists').on('change', function () {
        self.form.list_ids = window.$(".select-lists").select2("val");
      });

      this.showLists = true;
    },
  }
}
</script>