<template>
  <div class="modal fade" id="reviewer-insights-modal" tabindex="-1" role="dialog" aria-labelledby="basicDemoTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Reviewer Insights</h5>
        </div>
        <div class="modal-body">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th>Positive</th>
              <th>Negative</th>
            </tr>
            </thead>

            <tbody>
            <tr>
              <td>Positive 1</td>
              <td>Negative 1</td>
            </tr>
            <tr>
              <td>Positive 2</td>
              <td>Negative 2</td>
            </tr>
            <tr>
              <td>Positive 3</td>
              <td>Negative 3</td>
            </tr>
            <tr>
              <td>Positive 4</td>
              <td>Negative 4</td>
            </tr>
            <tr>
              <td>Positive 5</td>
              <td>Negative 5</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button"
                  data-dismiss="modal"
                  class="btn btn-dark btn-rounded">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('reviewer-insights-modal'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
  }
}
</script>