<template>
  
  <h6 class="text-black font-weight-bold my-4">Attribution insights will track your product’s organic ranking on Amazon for all of your keywords (coming soon)</h6>

  <!-- <p class="text-black">Enter the main 1-5 keywords that you most like to improve on</p> -->

  <p class="text-black">On the next page, we will suggest more keywords for your google ads based on those keywords</p>

  <div class="form-group mt-4">
    <input class="form-control"
           v-model="form.search_term[0]"
           placeholder="Required - Amazon search term"
           type="text">
  </div>
  <div class="form-group">
    <input class="form-control"
           v-model="form.search_term[1]"
           placeholder="Optional - Amazon search term"
           type="text">
  </div>
  <div class="form-group">
    <input class="form-control"
           v-model="form.search_term[2]"
           placeholder="Optional - Amazon search term"
           type="text">
  </div>
  <div class="form-group">
    <input class="form-control"
           v-model="form.search_term[3]"
           placeholder="Optional - Amazon search term"
           type="text">
  </div>
  <div class="form-group">
    <input class="form-control"
           v-model="form.search_term[5]"
           placeholder="Optional - Amazon search term"
           type="text">
  </div>
</template>

<script>
export default {
  props: ['formData', 'errors'],
  data() {
    return {
      adAccounts: [],
      form: {}
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    }
  },
}
</script>