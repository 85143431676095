<template>
  <div class="modal fade"
       id="modal-fb-preview"
       tabindex="-1"
       role="dialog"
       aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-add-cardLabel">Preview</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
          </button>
        </div><!--end modal-header-->
        <div class="modal-body">
          <div class="row">
            <div class="col-md-6" v-if="form">
              <div class="statbox widget box p-0">
                <div class="widget-content widget-content-area p-0 box-shadow-none">
                  <div class="table-responsive">
                    <table class="table table-bordered mb-0">
                      <tbody>
                      <tr>
                        <th>Account ID</th>
                        <td>{{ form.ad_account_id }}</td>
                      </tr>
                      <tr>
                        <th>Campaign</th>
                        <td>{{ form.name }}</td>
                      </tr>
                      <tr>
                        <th>Daily Budget</th>
                        <td>{{ form.daily_budget }}</td>
                      </tr>
                      <tr>
                        <th>Start Date</th>
                        <td>{{ form.start_time }}</td>
                      </tr>
                      <tr>
                        <th>End Date</th>
                        <td>{{ form.no_end_date ? 'No End Date' : form.end_time }}</td>
                      </tr>
                      <tr>
                        <th>Page</th>
                        <td>{{ pages.find(p => p.id === form.page) ? pages.find(p => p.id === form.page).name : '' }}</td>
                      </tr>
                      <tr>
                        <th>Instagram</th>
                        <td>{{ instagramAccounts.find(p => p.id === form.instagram_id) ? instagramAccounts.find(p => p.id === form.instagram_id).username : '' }}</td>
                      </tr>
                      <tr>
                        <th>ASIN</th>
                        <td>{{ form.asin }}</td>
                      </tr>
                      <tr>
                        <th>Ad Name</th>
                        <td>{{ form.ad_name }}</td>
                      </tr>
                      <tr>
                        <th>Headline</th>
                        <td>{{ form.headline }}</td>
                      </tr>
                      <tr>
                        <th>Description</th>
                        <td>{{ form.description }}</td>
                      </tr>
                      <tr>
                        <th>Primary Text</th>
                        <td>{{ form.primary_text }}</td>
                      </tr>
                      <tr>
                        <th>Amazon Account</th>
                        <td>{{ amazonAdAccounts.find(a => a.profileId == form.amazon_profile).accountInfo.name }}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>{{ form.status }}</td>
                      </tr>
                      <tr>
                        <th>Objective</th>
                        <td>{{ form.campaign_objective }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <select class="form-select form-control"
                      @change="getPreview"
                      :disabled="loading"
                      v-model="previewType">
                <option value="DESKTOP_FEED_STANDARD">DESKTOP_FEED_STANDARD</option>
                <option value="MESSENGER_MOBILE_INBOX_MEDIA">MESSENGER_MOBILE_INBOX_MEDIA</option>
                <option value="MESSENGER_MOBILE_STORY_MEDIA">MESSENGER_MOBILE_STORY_MEDIA</option>
                <option value="MOBILE_BANNER">MOBILE_BANNER</option>
                <option value="MOBILE_FEED_BASIC">MOBILE_FEED_BASIC</option>
                <option value="MOBILE_FEED_STANDARD">MOBILE_FEED_STANDARD</option>
                <option value="MOBILE_FULLWIDTH">MOBILE_FULLWIDTH</option>
                <option value="MOBILE_INTERSTITIAL">MOBILE_INTERSTITIAL</option>
                <option value="MOBILE_MEDIUM_RECTANGLE">MOBILE_MEDIUM_RECTANGLE</option>
                <option value="MOBILE_NATIVE">MOBILE_NATIVE</option>
                <option value="RIGHT_COLUMN_STANDARD">SUGGESTED_VIDEO_DESKTOP</option>
                <option value="SUGGESTED_VIDEO_MOBILE">SUGGESTED_VIDEO_MOBILE</option>
                <option value="WATCH_FEED_HOME">WATCH_FEED_HOME</option>
                <option value="WATCH_FEED_MOBILE">WATCH_FEED_MOBILE</option>
              </select>

              <hr>

              <div class="alert alert-danger border-0" role="alert" v-if="errorMessage">
                {{ errorMessage }}
              </div>

              <div v-if="loading" class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status"></div>
              </div>

              <div style="width: 100%"
                   ref="iframeContainer"
                   v-if="iframeHtml"
                   v-html="iframeHtml" />
            </div>
          </div>
        </div><!--end modal-body-->
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm me-1" data-dismiss="modal">Close</button>
          <button type="button"
                  @click.prevent="create"
                  class="btn btn-success btn-sm"><i class="fas fa-save"></i> Create Campaign</button>
        </div>
      </div><!--end modal-content-->
    </div><!--end modal-dialog-->
  </div><!--end modal-->
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      form: this.$store.state.fbAds.adForm,
      iframeHtml: null,
      errorMessage: null,
      previewType: 'DESKTOP_FEED_STANDARD',
      modal: null,
      pages: [],
      instagramAccounts: [],
    }
  },
  computed: {
    ...mapGetters({
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
    }),
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-fb-preview'));

  },
  methods: {
    show(pages, instagramAccounts) {
      this.pages = pages;
      this.instagramAccounts = instagramAccounts;
      this.iframeHtml = null;
      this.getPreview();
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    getPreview() {
      this.errorMessage = null;
      this.form = {...this.$store.state.fbAds.adForm, ...{previewType: this.previewType}};
      let formData = new FormData()
      this.iframeHtml = null;
      this.loading = true;

      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key] ? this.form[key] : '');
      })

      this.axios.post('/facebook/preview-campaign', formData)
        .then(res => {
          this.iframeHtml = res.data.data[0].body.replace('<iframe', '<iframe id="p" ')
        })
        .catch((err) => {
          if (err.response) {
            this.errorMessage =  "An error response (5xx, 4xx)";
          } else if (err.request) {
            this.errorMessage = "Received a response, and the request was never left";
          } else {
            this.errorMessage = "Error Happened";
          }
        })
        .finally(() => this.loading = false);
    },
    create() {
      this.hide();
      this.$emit('create')
    }
  }
}
</script>