<template>
  <div class="row">
    <div class="col-12">
      <div class="card" v-if="amazonProduct && amazonAdAccounts.length">
        <div class="card-body p-2">
          <table class="table list-group-flush">
            <tbody>
            <tr class="border-bottom">
              <td>Amazon Advertising Account</td>
              <td>{{ amazonAdAccounts.find(a => a.profileId == form.amazon_profile).accountInfo.name }}</td>
              <td class="text-right">
                <a href="#" @click="$emit('changeStep', 2)">
                  <i class="fa-solid fa-pen-to-square"></i>
                </a>
              </td>
            </tr>
            <tr class="border-bottom">
              <td>Target URL for Ad</td>
              <td>
                <a :href="amazonProduct.url"
                   class="text-info"
                   target="_blank">{{ amazonProduct.url }}</a>
              </td>
              <td></td>
            </tr>
            <tr class="border-bottom">
              <td>Ad</td>
              <td>
                <preview :formData="form" />
              </td>
              <td></td>
            </tr>
            <tr class="border-bottom">
              <td>Targeting Keywords</td>
              <td>
                <span class="badge badge-success mr-2 mb-2"
                      v-for="(key, i) in form.keywords"
                      :key="'key_'+i">{{ key.keyword }}
                </span>
              </td>
              <td class="text-right">
                <a href="#" @click="$emit('changeStep', 4)">
                  <i class="fa-solid fa-pen-to-square"></i>
                </a>
              </td>
            </tr>
            <tr class="border-bottom">
              <td>Daily Budget</td>
              <td>${{ form.budget }}</td>
              <td class="text-right">
                <a href="#" @click="$emit('changeStep', 2)">
                  <i class="fa-solid fa-pen-to-square"></i>
                </a>
              </td>
            </tr>
            <tr class="border-bottom">
              <td>Maximum cost-per-click bid</td>
              <td>{{ form.max_cpc ? ('$' + form.max_cpc) : 'N/A' }}</td>
              <td class="text-right">
                <a href="#" @click="$emit('changeStep', 2)">
                  <i class="fa-solid fa-pen-to-square"></i>
                </a>
              </td>
            </tr>
            <tr class="border-bottom">
              <td>Campaign Name</td>
              <td>[Attribution Insights] {{ form.name }}</td>
              <td class="text-right">
                <a href="#" @click="$emit('changeStep', 2)">
                  <i class="fa-solid fa-pen-to-square"></i>
                </a>
              </td>
            </tr>
            <tr class="border-bottom">
              <td>Location</td>
              <td>
                <select class="form-control form-control-sm"
                        v-model="form.criteria_id">
                  <option value="">All Countries</option>
                  <option value="2840">United States</option>

                  <template v-for="country in countries"
                            :key="'country_'+country.id">
                    <option :value="country.criteria_id"
                            v-if="country.criteria_id !== 2840">
                      {{ country.name }}
                    </option>
                  </template>
                </select>
              </td>
              <td class="text-right"></td>
            </tr>
            <tr>
              <td>Status</td>
              <td>
                <select class="form-control form-control-sm"
                        v-model="form.status">
                  <option value="PAUSED">PAUSED</option>
                  <option value="ACTIVE">ACTIVE</option>
                </select>
              </td>
              <td class="text-right"></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Preview from "@/views/create_campaign/google_wizard/Preview";
export default {
  components: {Preview},
  props: ['formData', 'errors'],
  data() {
    return {
      form: {},
      countries: []
    }
  },
  computed: {
    ...mapGetters({
      amazonAdAccounts: 'adAccounts/getAmazonAccounts',
      amazonProduct: 'googleWizard/getAmazonProduct'
    }),
  },
  created() {
    this.loadCountries();
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    }
  },
  methods: {
    loadCountries() {
      this.countries = [];

      this.axios.get('/countries')
        .then(res => {
          this.countries = res.data.data;
        })
    }
  }
}
</script>