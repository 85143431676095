<template>
  <h6 class="text-black font-weight-bold mb-4 mt-4">Enter the product ASIN you would like to create a Google Ads campaign for:</h6>

  <div class="row">
    <div class="col-6">
      <select class="form-control"
              @change="changeMarketplace"
              v-model="form.country_code">
        <option v-for="(marketplace, i) in amazonMarketplaces"
                :value="marketplace.country_code"
                :key="'marketplace_'+i">{{ marketplace.country }} ({{ marketplace.host }})</option>
      </select>
    </div>
    <div class="col-6">
      <div class="autocomplete" style="width:300px;">
        <input type="text"
               v-model="form.asin"
               @keyup="getAmazonProduct($event)"
               class="form-control"
               placeholder="Enter 10 letters ASIN (e.g. B0S60VG188)">

        <div class="autocomplete-items" v-if="filteredProducts.length">
          <div v-for="product in filteredProducts"
               @click="selectProduct(product)"
               :key="'product_'+product.asin">
            <strong>{{ product.asin }}</strong>
            <span class="float-right">{{ getSymbolFromCurrency(this.marketplace.currency) + product.price }}</span>
            <p>{{ product.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <template v-if="amazonProduct">
    <div class="row mt-4">
      <div class="col-md-6 col-12">
        <h6 class="text-black font-weight-bold">Amazon Domain</h6>
        {{ amazonMarketplaces.find(m => m.country_code === this.form.country_code).host }}
      </div>
      <div class="col-md-6 col-12">
        <h6 class="text-black font-weight-bold">Amazon Product ASIN</h6>
        {{ amazonProduct.asin }}
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <h6 class="text-black font-weight-bold">Product URL</h6>
        <a class="text-info"
           :href="amazonProduct.url"
           target="_blank">{{ amazonProduct.url }}</a>
      </div>

    </div>
    <hr>
    <div class="row">
      <div class="col-4">
        <h6 class="text-black font-weight-bold">Rating</h6>
        {{ amazonProduct.reviews.rating }}
      </div>
      <div class="col-4">
        <h6 class="text-black font-weight-bold">Price</h6>
        {{ amazonProduct.price.currency + ' ' + amazonProduct.price.current_price }}
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <h6 class="text-black font-weight-bold">Product Title</h6>
        <p class="tab-content mt-2">{{ amazonProduct.title }}</p>
      </div>
    </div>
  </template>
</template>

<script>
import {mapGetters} from "vuex";
import getSymbolFromCurrency from "currency-symbol-map";

export default {
  props: ['formData', 'errors'],
  data() {
    return {
      form: {},
      products: []
    }
  },
  beforeMount() {
    this.form = this.formData;
  },
  beforeUpdate() {
    this.form = this.formData;
  },
  watch: {
    errors: {
      handler(newValue) {
        this.allErrors = newValue;
      },
      deep: true
    },
    formData: {
      handler(newValue) {
        this.$emit('update:formData', newValue);
      },
      deep: true
    },

  },
  computed: {
    ...mapGetters({
      amazonProduct: 'googleWizard/getAmazonProduct',
      amazonMarketplaces: 'adAccounts/getAmazonMarketplaces'
    }),
    filteredProducts() {
      const searchTerm = this.form.asin.toLowerCase();

      if (!searchTerm) {
        return []
      }

      return this.products.filter(product => {
        return product.asin.toLowerCase() !== searchTerm && product.asin.toLowerCase().indexOf(searchTerm) > -1;
      }).slice(0, 10);
    },
    marketplace() {
      return this.amazonMarketplaces.find(m => m.country_code === this.form.country_code);
    }
  },
  mounted() {
    this.changeMarketplace();
  },
  methods: {
    getSymbolFromCurrency,
    selectProduct(product) {
      this.form.asin = product.asin;
      this.getAmazonProduct();
    },
    changeMarketplace() {
      this.getAmazonProduct();

      this.loadAmazonProducts(this.marketplace.marketplace_id);
    },
    getAmazonProduct() {
      if (this.form.asin.length === 10 &&
          (!this.amazonProduct || this.amazonProduct.asin !== this.form.asin.toUpperCase())) {
        this.$store.dispatch('googleWizard/getAmazonProduct', {asin: this.form.asin, countryCode: this.form.country_code})
            .then(() => {
              this.form.link = this.amazonProduct.url;
            }).catch((e) => {
          if(e.status === 500) {
            this.showFailMsg("This ASIN doesn't exist");
          }
        })
      }
    },
    loadAmazonProducts(marketplaceId) {
      this.products = [];

      this.axios.get('/amazon/products/' + marketplaceId)
          .then(res => {
            this.products = res.data.data;
          })
    }
  }
}
</script>

<style scoped>

.tab-header h3 {
  color: #3b3f5c;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.tab-content{
  color: #000000;
  font-size: 16px;
  margin-top: 20px;
  line-height: 30px;
}
</style>